/* Customize your main colors in :root variables */
:root {
  --main-background-color: transparent;
  --card-background-color: transparent;
  --card-background-lighter-color: transparent;
  --main-text-color:white;
  --title-text-color:white;
}

body{
  background-color:var(--main-background-color);
  /* font-family: 'Patrick Hand', cursive;
  font-size: 1.2em; */
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

.wallet-adapter-button{
  padding: 5px 10px !important;
}

/*! CSS Used from: Embedded */
.mintnowbutton{color:black;border-radius:22px;font-size:85px;font-family:futurespore;text-align:center;align-self:center!important;line-height:85px;max-width:480px;max-height:95px;background-color:white;margin:auto;}
/*! CSS Used from: Embedded */
*,:after,:before{box-sizing:border-box;border:0 solid white;}
:after,:before{--tw-content:"";}
:-moz-focusring{outline:auto;}
:disabled{cursor:default;}
*,:after,:before{--tw-translate-x:0;--tw-translate-y:0;--tw-rotate:0;--tw-skew-x:0;--tw-skew-y:0;--tw-scale-x:1;--tw-scale-y:1;--tw-scroll-snap-strictness:proximity;--tw-ring-offset-width:0px;--tw-ring-offset-color:#fff;--tw-ring-color:rgb(59 130 246/0.5);--tw-ring-offset-shadow:0 0 #0000;--tw-ring-shadow:0 0 #0000;--tw-shadow:0 0 #0000;--tw-shadow-colored:0 0 #0000;}
/*! CSS Used fontfaces */


@font-face {
  font-family: 'futurespore';
  src: url('css/futurespore.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'generica';
  src: url('css/fonts/Generica.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'generica';
  src: url('css/fonts/GenericaBold.otf') format('truetype');
  font-weight: bold;
}

button {
  border: none;
  background-color: white;
  border-radius: 50%;
  height: 2em;
  color: #ffffff;
  text-transform: uppercase;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  position: relative;
  height: 100vh;
  text-align: center;
  justify-content: center;
  font-family: futurespore;
}

.top-menu {
  max-width: 100%;
  margin-left: 3%;
  margin-right: 3%;
}

h1 {
  font-size: 5em;
  font-family: futurespore;
  color: #fff;
  margin: 0;
  padding: 0;
}

.section {
  text-align: center;
  overflow: hidden;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-color: black;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  z-index: 3;
}

#section0 .layer {
  position: absolute;
  z-index: 4;
  width: 100%;
  left: 0;
  top: 50px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#section0 {
  overflow: hidden;
}

#infoMenu li a {
  color: #fff;
}

video::-webkit-media-controls {
  display: none !important;
}

.topnav {
  position: relative;
}

.topnav-left a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  opacity: 0.5;
}

.topnav-centered a {
  margin-top: 44px;
}

.topnav-centered a img:hover {
  opacity: 0.9;
}

.topnav a.active {
  color: white;
}

.topnav-centered a {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topnav-left img {
  max-height: 50px;
}

.topnav-right img {
  max-width: 300px;
  height: auto;
}

.topnav-right a {
  padding-top: 22px;
  max-width: 300px;
}

.mobilespace {
  display: none;
}



@media screen and (min-width: 600px) and (max-width: 768px) {
  .topnav-right img {
      max-width: 100%;
      height: auto;
      padding-top: 4px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .topnav-right img {
      max-width: 100%;
      height: auto;
  }
  .topnav-left img {
      max-width: 100%;
      height: auto;
      max-height: 48px;
  }
}

@media screen and (max-width: 600px) {
  .discord_icon {
      margin-block: -8px;
  }
  .topnav {
      display: block;
      height: auto;
  }
  .topnav a {
      float: none;
      display: inline;
  }
  .topnav-centered a {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
  }
  .topnav-left {
      display: block;
      margin-bottom: 30px;
  }
  .topnav-right {
      display: block;
      margin-bottom: 30px;
  }

  #section0 .layer,
  .top-menu,
  .topnav {
      height: 100%;
  }
}

.main-timeline-custom::before {
  display: none;
}

.main-timeline2::before{
  display: none;
}

.mintnowbuttonwrapper{
  padding: 8px 9px;
  background:  linear-gradient(to right,#602d5f,#9ecad6);
  border-radius: 22px;
  margin-left: auto;
  margin-right: auto;
  max-width: 496px;
  max-height: 112px;
  margin-top: 70px;
}
.mintnowbutton{
  color: black;
  border-radius: 22px;
  font-size: 85px;
  /* padding: 1px 30px; */
  font-family: futurespore;
  text-align: center;
  align-self: center!important;
  line-height: 85px;
  max-width:480px;
  max-height:95px;
  background-color: white;
  margin: auto;
}

.countdown{
font-size: 72px;
font-family: futurespore;
line-height: 96px;
/* margin-bottom: 72px; */
color: white;
background-color: transparent;
text-align: center;
}
.finish.countdown{
font-family: Generica;
}

.countdownbg{
height: 98px;
width: 108px;
background-color: white;
color: black;
border-radius: 22px;
display: inline-block;
}
.countdownspacer{
width: 50px;
height: 98px;
background-color: transparent;
color: white;
display: inline-block;
}
.gif::before{

}
.main-timeline2 .title{
font-size: 78px;
line-height: 78px;
text-align: center;
}



@media only screen and (max-width: 767px){
.main-timeline-custom {
/* padding-top: 50px; */
padding: 200px 0 0 0 !important;
}
.main-timeline2  .timelineimgcontainer{
margin: 0 0 0px 0 !important;
}
.mintnowbuttonwrapper{
max-width:80%;
font-size: 48px;
line-height: 48px;
/* margin: 40px auto 20px auto; */
margin-top: 60px;
/* margin-left: 10px; */
}
}

.timelineimgcontainerwrappers{
background: linear-gradient(to right,#602d5f,#9ecad6);;
padding: 10px !important;
max-width: 750px;
display: flex;
border-radius: 22px;
/* margin-top: 100px; */
}

.timelineimgcontainer.gif{
background: black;
padding: 0px;

margin: 10px !important;
display: flex;
border-radius: 22px;
}